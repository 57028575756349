import React from "react"
import clsx from "clsx"
import { useMediaQuery } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Seo from "../components/SEO/seo";
import Layout from "../components/layout"

const useStyles = makeStyles(({ palette, ...theme }) => ({
  card: {
    maxWidth: 700,
    "& .image-border": {
      border: "3px solid rgba(var(--primary),0.3)",
      background: palette.primary.contrastText,
    },

    "&:after": {
      content: '" "',
      position: "absolute",
      display: "block",
      top: 0,
      bottom: 0,
      left: "calc(50% - 120px)",
      right: "calc(50% - 175px)",
      background: "rgba(var(--primary),0.15)",
      clipPath: "polygon(35% 0%, 100% 0%, 65% 100%, 0% 100%)",
      zIndex: -1,
      [theme.breakpoints.down("xs")]: {
        right: 0,
        left: 0,
        clipPath: "none",
      },
    },
  },
  intro: {
    padding: "200px 0 0 !important",
    overflow: "visible !important",

    [theme.breakpoints.down("sm")]: {
      padding: "60px 0 0 !important",
    },
  },
  styleDiv: {
    paddingBottom: 100
  }

}))

const Testimonial9 = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))



  return (
    <Layout>
      <div className={clsx("section ", classes.intro)} id="testimonial9">
        <Seo title={"Conocenos |Disdelsa"}

          description={"Disdelsa, distribucion y comercializacion de productos de limpieza "}
          keywords={"Disdelsa, Productos de limpieza, suministros de limpieza "}
          link={"https://disdelsa.com/Conocenos"}
          title={" Conocenos | Disdelsa "}
          image="https://disdelsa.com/imagenes/BannerImagen-img2059-19-8-2020-84427.png"
        />
        <div className="container text-center">


          <div className={classes.styleDiv} >
            <div
              className={clsx({
                "flex-wrap": isMobile,
              })}
            >
              <h1>
                “Un mundo limpio mediante al reusó de envases plásticos”
              </h1>
              <br />
              <br />
              <h5 >
                Somos una empresa guatemalteca que provee una marca y productos  de calidad satisfaciendo así a nuestros clientes y consumidores. En Mitientida Megax creamos oportunidades para emprendedores y generamos un impacto positivo en el desarrollo económico.
                <br />
                <br />
                Visión: Seguir permitiendo la creación de amplias oportunidades laborales para los emprendedores distribuyendo químicos de limpieza desde su negocio o casa generando así ingresos adicionales
              </h5>
              <br />
              <h4>
                Emprender con MEGAX se trata de crear valor, solucionar problemas, satisfacer necesidades y transformar positivamente nuestro entorno.
              </h4>
            </div>
          </div>


        </div>
      </div>
    </Layout>
  )
}

export default Testimonial9
